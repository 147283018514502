import React from "react";
import GeneralStyle from "../css/general.css";
import Style from "../css/style.css";
import Queries from "../css/queries.css";
import Hero from "./hero/Hero";
import Services from "./services/Services";
import Cta from "./cta/Cta";
import Testimonials from "./testimonials/Testimonial";
import JoinUs from "./join-us/Join-us";
import Footer from "./footer/Footer";
import Header from "./header/Header";

class App extends React.Component {
  render() {
    return (
      <div>
        <Header />
        <Hero />
        <Services />
        <Cta />
        <Testimonials />
        <JoinUs />
        <Footer />
      </div>
    );
  }
}

export default App;
