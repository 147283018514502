import React from "react";
import { useState, useEffect } from "react";

// const numSlides =  round (testimonialsArray.length/testimonialsPerSlide);

const RenderTestimonials = (
  testimonialsArray,
  dotsArray,
  isMobile,
  numSlides,
  testimonialsPerSlide
) => {
  const [currentSlide, setCurrentSlide] = useState(0);

  let prevSlide = 0;
  const testimonialsDotsEl = document.getElementsByClassName(
    "dot-testimonials"
  );

  useEffect(() => {
    if (!isMobile) {
      testimonialsDotsEl[0].classList.add("dot-active-testimonials");
    }
    setCurrentSlide(0);
  }, [isMobile]);

  const onHandleClickDots = (e) => {
    if (isMobile) {
      if (e.target.classList[0] === "dot-testimonials") {
        prevSlide = currentSlide;
        testimonialsDotsEl[prevSlide].classList.remove(
          "dot-active-testimonials"
        );
        switch (e.target.id) {
          case "Testimonial dot 1": {
            e.target.classList.add("dot-active-testimonials");
            setCurrentSlide(0);
            break;
          }
          case "Testimonial dot 2": {
            e.target.classList.add("dot-active-testimonials");
            setCurrentSlide(1);
            break;
          }
          case "Testimonial dot 3": {
            e.target.classList.add("dot-active-testimonials");
            setCurrentSlide(2);
            break;
          }
          case "Testimonial dot 4": {
            e.target.classList.add("dot-active-testimonials");
            setCurrentSlide(3);
            break;
          }
          case "Testimonial dot 5": {
            e.target.classList.add("dot-active-testimonials");
            setCurrentSlide(4);
            break;
          }
          case "Testimonial dot 6": {
            e.target.classList.add("dot-active-testimonials");
            setCurrentSlide(5);
            break;
          }
          case "Testimonial dot 7": {
            e.target.classList.add("dot-active-testimonials");
            setCurrentSlide(6);
            break;
          }
          case "Testimonial dot 8": {
            e.target.classList.add("dot-active-testimonials");
            setCurrentSlide(7);
            break;
          }
          case "Testimonial dot 9": {
            e.target.classList.add("dot-active-testimonials");
            setCurrentSlide(8);
            break;
          }
          case "Testimonial dot 10": {
            e.target.classList.add("dot-active-testimonials");
            setCurrentSlide(9);
            break;
          }
          case "Testimonial dot 11": {
            e.target.classList.add("dot-active-testimonials");
            setCurrentSlide(10);
            break;
          }
          case "Testimonial dot 12": {
            e.target.classList.add("dot-active-testimonials");
            setCurrentSlide(11);
            break;
          }
          default: {
            return;
          }
        }
      }
    }
    if (!isMobile) {
      if (e.target.classList[0] === "dot-testimonials") {
        prevSlide = currentSlide;
        testimonialsDotsEl[prevSlide].classList.remove(
          "dot-active-testimonials"
        );
        switch (e.target.id) {
          case "Testimonial dot 1": {
            e.target.classList.add("dot-active-testimonials");
            setCurrentSlide(0);
            break;
          }
          case "Testimonial dot 2": {
            e.target.classList.add("dot-active-testimonials");
            setCurrentSlide(1);
            break;
          }
          case "Testimonial dot 3": {
            e.target.classList.add("dot-active-testimonials");
            setCurrentSlide(2);
            break;
          }
          case "Testimonial dot 4": {
            e.target.classList.add("dot-active-testimonials");
            setCurrentSlide(3);
            break;
          }
          default: {
            return;
          }
        }
      }
    }
  };

  const onHandleClickSlider = (e) => {
    prevSlide = currentSlide;
    testimonialsDotsEl[prevSlide].classList.remove("dot-active-testimonials");

    //click flecha derecha
    if (e.target.name === "chevron-forward-circle-outline") {
      if (prevSlide === numSlides - 1) {
        testimonialsDotsEl[0].classList.add("dot-active-testimonials");
        setCurrentSlide(0);
      } else {
        testimonialsDotsEl[prevSlide + 1].classList.add(
          "dot-active-testimonials"
        );
        setCurrentSlide(prevSlide + 1);
      }
    }
    //click flecha izq
    if (e.target.name === "chevron-back-circle-outline") {
      if (prevSlide === 0) {
        testimonialsDotsEl[numSlides - 1].classList.add(
          "dot-active-testimonials"
        );
        setCurrentSlide(numSlides - 1);
      } else {
        testimonialsDotsEl[prevSlide - 1].classList.add(
          "dot-active-testimonials"
        );
        setCurrentSlide(prevSlide - 1);
      }
    }
  };
  return (
    <div className="container">
      <div className="slider-testimonials">
        <div className="arrow-slider-testimonials">
          <div className="arrow-left-slider-testimonials">
            <button
              className="btn-arrow-testimonials"
              onClick={onHandleClickSlider}
            >
              <ion-icon
                name="chevron-back-circle-outline"
                class="arrow-icon-testimonials"
              ></ion-icon>
            </button>
          </div>
          <div className="slide-container-testimonials">
            {testimonialsArray
              .filter((testimonial) => {
                let testimonialItem = 0;
                switch (testimonial.id) {
                  case "Testimonial 1": {
                    testimonialItem = 1;
                    break;
                  }
                  case "Testimonial 2": {
                    testimonialItem = 2;
                    break;
                  }
                  case "Testimonial 3": {
                    testimonialItem = 3;
                    break;
                  }
                  case "Testimonial 4": {
                    testimonialItem = 4;
                    break;
                  }
                  case "Testimonial 5": {
                    testimonialItem = 5;
                    break;
                  }
                  case "Testimonial 6": {
                    testimonialItem = 6;
                    break;
                  }
                  case "Testimonial 7": {
                    testimonialItem = 7;
                    break;
                  }
                  case "Testimonial 8": {
                    testimonialItem = 8;
                    break;
                  }
                  case "Testimonial 9": {
                    testimonialItem = 9;
                    break;
                  }
                  case "Testimonial 10": {
                    testimonialItem = 10;
                    break;
                  }
                  case "Testimonial 11": {
                    testimonialItem = 11;
                    break;
                  }
                  case "Testimonial 12": {
                    testimonialItem = 12;
                    break;
                  }

                  default:
                }

                return (
                  currentSlide * testimonialsPerSlide < testimonialItem &&
                  testimonialItem <= (currentSlide + 1) * testimonialsPerSlide
                );
              })
              .map((testimonial) => {
                return (
                  <div className="slide">
                    <div className="testimonial">
                      <figure
                        className="figure-testimonial"
                        key={testimonial.id}
                      >
                        <img
                          className="testimonial-img"
                          alt={testimonial.imgAlt}
                          src={testimonial.img}
                        />
                        <div className="testimonial-content">
                          <blockquote className="testimonial-text">
                            {testimonial.comment}
                          </blockquote>
                          <div className="testimonial-author">
                            &mdash; {testimonial.author}
                          </div>
                        </div>
                      </figure>
                    </div>
                  </div>
                );
              })}
          </div>
          <div className="arrow-right-slider-testimonials">
            <button
              className="btn-arrow-testimonials"
              onClick={onHandleClickSlider}
            >
              <ion-icon
                name="chevron-forward-circle-outline"
                class="arrow-icon-testimonials"
              ></ion-icon>
            </button>
          </div>
        </div>
        <div className="dot-slider-testimonials">
          {dotsArray.map((dot) => {
            return (
              <button
                className="btn-dot-testimonials"
                onClick={onHandleClickDots}
              >
                <ion-icon
                  name="ellipse-outline"
                  class="dot-testimonials"
                  id={dot.id}
                ></ion-icon>
              </button>
            );
          })}
        </div>
      </div>
    </div>
  );
};

export default RenderTestimonials;
