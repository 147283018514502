import React from "react";
import emailjs from "emailjs-com";

class JoinUsForm extends React.Component {
  state = { name: "", email: "", message: "" };

  onFormSubmit = (e) => {
    e.preventDefault();
    emailjs
      .sendForm("gmail", "join_us_contact_form", e.target, "nV3tTFSl_x7gBrCSM")
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
    alert(
      `Gracias por tu mensaje! Enseguida te contactaremos a la dirección de email que nos has proporcionado.`
    );
    this.setState({ name: "", email: "", message: "" });
  };
  render() {
    return (
      <form className="join-us-form" onSubmit={this.onFormSubmit}>
        <div>
          <label htmlFor="full-name-join-us">Nombre completo</label>
          <input
            name="full-name-join-us"
            id="full-name-join-us"
            type="text"
            placeholder="Tu Nombre"
            required
            value={this.state.name}
            onChange={(e) => this.setState({ name: e.target.value })}
          />
        </div>
        <div>
          <label htmlFor="email-join-us">Email</label>
          <input
            name="email-join-us"
            id="email-join-us"
            type="email"
            placeholder="tuemail@mail.com"
            required
            value={this.state.email}
            onChange={(e) => this.setState({ email: e.target.value })}
          />
        </div>
        <div>
          <label htmlFor="message">Mensaje</label>
          <textarea
            name="message"
            id="message"
            type="text"
            placeholder="Cuentanos acerca de ti..."
            required
            value={this.state.message}
            onChange={(e) => this.setState({ message: e.target.value })}
          ></textarea>
        </div>
        <button className="btn btn--form" type="submit">
          Enviar
        </button>
      </form>
    );
  }
}

export default JoinUsForm;
