import React from "react";
import CtaForm from "./CtaForm";

class Cta extends React.Component {
  render() {
    return (
      <section className="cta" id="cta">
        <div className="container">
          <div className="cta-grid">
            <div className="form-box-cta">
              <h2 className="heading-secondary cta-title">
                Prueba una clase gratis!
              </h2>
              <p className="cta-text">
                Si estás interesad@ en alguno de nuestros servicios pero te
                gustaría probar una clase antes, puedes hacerlo! Completa el
                formulario a continuación con tus datos y los campos requeridos,
                nos pondremos en contacto contigo a la brevedad.
              </p>
              <CtaForm />
            </div>
            <div
              className="cta-img-box"
              role="img"
              aria-label="student in front of
          her computer taking notes"
            ></div>
          </div>
        </div>
      </section>
    );
  }
}

export default Cta;
