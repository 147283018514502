// image testimonials
import React from "react";
import ImgJosefinaPonce from "../img/testimonials/avatar_josefina_ponce.png";
import ImgIntiPellerei from "../img/testimonials/avatar_inti_pellerei.png";
import ImgEneasDosSantos from "../img/testimonials/avatar_eneas_dossantos.png";
import ImgIgnacioCuri from "../img/testimonials/avatar_ignacio_curi.png";
import ImgManuelStolowicz from "../img/testimonials/avatar_manuel_stolowicz.png";
import ImgManuelGarcia from "../img/testimonials/avatar_manuel_garcia.png";
import ImgAgustinaLaxague from "../img/testimonials/avatar_agustina_laxague.png";
import ImgVictoriaOhaian from "../img/testimonials/avatar_victoria_ohaian.png";
import ImgSofiaRodriguez from "../img/testimonials/avatar_sofia_rodriguez.png";
import ImgBelenEspino from "../img/testimonials/avatar_belen_espino.png";
import ImgMaximilianoGarcia from "../img/testimonials/avatar_maximiliano_garcia.png";
import ImgEnzoPeña from "../img/testimonials/avatar_enzo_peña.png";

// Port View Width
export const smallTabletsViewWidth = 970;
export const mobileViewWidth = 672;

//Arrays and constants services
export const serviceFeatureArray = [
  {
    featureIcon: "calendar-outline",
    featureTitle: "Arma tus horarios",
    featureDescription:
      "Tenemos en cuenta tus horarios a la hora de agendar las clases. Los dias y horarios de clase los coordinamos junto a ti.",
  },
  {
    featureIcon: "logo-whatsapp",
    featureTitle: "Grupo de Whatsapp",
    featureDescription:
      "Grupos de whatsapp entre alumnos y profesores funcionando 24*7. Podrás mandarnos tus consultas en cualquier momento y desde cualquier lugar.",
  },
  {
    featureIcon: "star-outline",
    featureTitle: "El mejor rating",
    featureDescription:
      "De acuerdo a la encuesta de satisfacción que realizamos al final de cada semestre y preparación, más del 93% de nuestros alumnos nos recomiendan.",
  },
  {
    featureIcon: "people-outline",
    featureTitle: "Grupos reducidos",
    featureDescription:
      "Nuestros grupos de clase son de máximo 10 estudiantes. De esta forma podemos brindarte mejor atención y seguimiento.",
  },
  {
    featureIcon: "flask-outline",
    featureTitle: "Más de 20 materias",
    featureDescription:
      "Todos nuestros servicios están disponibles para más de 20 materias de la FING. Año a año incorporamos nuevas materias para poder aydarte.",
  },

  {
    featureIcon: "school-outline",
    featureTitle: "Profes de la FING",
    featureDescription:
      "Nuestro grupo docente está conformado por alumnos avanzados e ingenieros egresados de la FING y saben de primera mano lo que necesitas.",
  },
  {
    featureIcon: "book-outline",
    featureTitle: "Recursos exclusivos",
    featureDescription:
      "Contamos con mucho material de apoyo elavorado por nuestros profesores que te será de gran ayuda a la hora de estudiar.",
  },
  {
    featureIcon: "laptop-outline",
    featureTitle: "Modalidad online",
    featureDescription:
      "La modalidad online permite que tengas tus clases desde donde quieras, sin que tengas que invertir tiempo o dinero en trasladarte.",
  },
];
export const serviceFlyerArray = [
  {
    serviceClass: "service-plan",
    serviceName: "Preparación de parcial",
    serviceStartingDate: "2-3 semanas antes de la fecha del parcial",
    serviceDuration: "2 semanas",
    serviceItems: [
      `6 clases online de 120 minutos`,
      `Resolución de parciales anteriores`,
      `Repaso teórico`,
      `Grupo de whatsapp para dudas y consultas 24x7`,
      `Grupos de clase reducidos`,
      `Acceso a material de Fing Twice`,
    ],
  },
  {
    serviceClass: "service-plan",
    serviceName: "Preparación de examen",
    serviceStartingDate: "3-4 semanas antes de la fecha del examen",
    serviceDuration: "3 semanas",
    serviceItems: [
      `8 clases online de 120 minutos`,
      `Resolución de exámenes anteriores`,
      `Repaso teórico`,
      `Grupo de whatsapp para dudas y consultas 24x7`,
      `Grupos de clase reducidos`,
      `Acceso a material de Fing Twice`,
    ],
  },
  {
    serviceClass: "service-plan",
    serviceName: "Clases particulares",
    serviceStartingDate: "A petición del alumn@",
    serviceDuration: "A petición del alumn@",
    serviceItems: [
      `Clases online de 60 minutos a demanda`,
      `Clases individuales, con opción a grupo reducido si el estudiante lo desea`,
      `Hoarios a convenir con el alumn@`,
      `Elección de temas y ejercicios a disposición del alumn@`,
      `Grupo de whatsapp para dudas y consultas 24x7`,
      `Acceso a material de Fing Twice`,
    ],
  },
  {
    serviceClass: "service-plan curso-paralelo",
    serviceName: "Curso paralelo",
    serviceStartingDate: "Junto al semestre",
    serviceDuration: "Todo el semestre",
    serviceItems: [
      `2 clases online de 120 minutos por semana`,
      `Resolución paso a paso de ejercicios del práctico`,
      `Repaso teórico`,
      `Grupo de whatsapp para dudas y consultas 24x7`,
      `Grupos de clase reducidos`,
      `Acceso a material de Fing Twice`,
    ],
  },
];
export const serviceFeatureDotsArray = [
  {
    id: "service feature dot 0",
    name: "dot 1",
  },
  {
    id: "service feature dot 1",
    name: "dot 2",
  },
  {
    id: "service feature dot 2",
    name: "dot 3",
  },
  {
    id: "service feature dot 3",
    name: "dot 4",
  },
  {
    id: "service feature dot 4",
    name: "dot 5",
  },
  {
    id: "service feature dot 5",
    name: "dot 6",
  },
  {
    id: "service feature dot 6",
    name: "dot 7",
  },
  {
    id: "service feature dot 7",
    name: "dot 8",
  },
];

//Arrays and constants testimonials
export const testimonialsPerSlideMobile = 1;
export const testimonialsPerSlideNonMobile = 3;
export const testimonialsNumSlidesMobile = 12;
export const testimonialsNumSlidesNonMobile = 4;
export const testimonialsArray = [
  {
    id: "Testimonial 1",
    author: "Josefina Ponce de León",
    comment:
      "Muy buena la academia y muy buenos profesores. Siempre hay buena disposición a la hora de consultar dudas.",
    img: ImgJosefinaPonce,
    imgAlt: "avatar Josefina Ponce",
  },
  {
    id: "Testimonial 2",
    author: "Inti Pellerei",
    comment:
      "Muy recomendable, las clases abordan los temas más importante de cara al parcial o examen y siempre se explican las cosas de forma clara y concreta.",
    img: ImgIntiPellerei,
    imgAlt: "avatar Inti Pellerei",
  },
  {
    id: "Testimonial 3",
    author: "Eneas Dos Santos",
    comment:
      "Preparé varias materias con FT y la verdad que unos genios los profesores, muy atentos a las consultas y mucha claridad en los conceptos. Súper recomendable.",
    img: ImgEneasDosSantos,
    imgAlt: "avatar Eneas Dos Santos",
  },
  {
    id: "Testimonial 4",
    author: "Vicotria Ohaian",
    comment:
      "Las clases estan buenas, con claridad para explicar y siempre hay disposicion para contestar tanto en la clase como por wpp",
    img: ImgVictoriaOhaian,
    imgAlt: "avatar Victoria Ohaian",
  },
  {
    id: "Testimonial 5",
    author: "Belén Espino",
    comment:
      "La academia me ayudó mucho a que la carrera se hiciera más llevadera, todos los profesores son excelentes y no solo te dan la clase, sino que se preocupan porque de verdad entiendas los temas.",
    img: ImgBelenEspino,
    imgAlt: "avatar Belen Espino",
  },
  {
    id: "Testimonial 6",
    author: "Manuel Garcia",
    comment:
      "Mi experiencia con la academia es excelente, los profesores explican muy bien y siempre que tuve dudas pude despejarlas. Sumamente recomendable para cualquiera que necesite una mano y no le salgan los prácticos solo.",
    img: ImgManuelGarcia,
    imgAlt: "avatar Manuel Garcia",
  },
  {
    id: "Testimonial 7",
    author: "Enzo Peña",
    comment:
      "Cursé en la academia el paralelo de GAL 1, en mi experiencia todo fue muy prolijo y profesional. Destaco la disposición del docente (Federico) para flexibilizar los horarios y responder dudas fuera de hora. Recomiendo 100% este tipo de cursos ya que entiendo que nos ayudan a optimizar tiempos dándonos respuestas instantáneas a dudas que podríamos googlear por horas. Otro ítem a destacar (al menos en mi experiencia) es que las clases son de pocos alumnos y esto le permite al docente disponer la suficiente atención a cada alumno.",
    img: ImgEnzoPeña,
    imgAlt: "avatar Enzo Peña",
  },

  {
    id: "Testimonial 8",
    author: "Ignacio Curi",
    comment:
      "Uso y recomiendo!. Buenos resultados en dos exámenes que venía pateando!.",
    img: ImgIgnacioCuri,
    imgAlt: "avatar Ignacio Curi",
  },
  {
    id: "Testimonial 9",
    author: "Manuel Stolowicz",
    comment:
      "Las clases de mecánica con Matías son totalmente recomendables. Bien enfocadas al práctico del curso de forma de poder entender como hacer los diversos ejercicios, logró bajar a tierra los conceptos. Muy buen curso.",
    img: ImgManuelStolowicz,
    imgAlt: "avatar Manuel Stolowicz",
  },
  {
    id: "Testimonial 10",
    author: "Sofía Rodriguez",
    comment:
      "La academia siempre cumplió con mis expectativas, buenas las clases y los profesores siempre despejando dudas.",
    img: ImgSofiaRodriguez,
    imgAlt: "avatar Sofía Rodriguez",
  },
  {
    id: "Testimonial 11",
    author: "Agustina Laxague",
    comment:
      "Destaco que al ser una academia online existe la posibilidad de optar por clases desde el lugar en donde te encuentres También es una realidad, que en donde te encuentras quizas es difícil conseguir docentes que dicten los cursos que el estudiante necesita, y al contar con esta modalidad todo se hace más flexible.",
    img: ImgAgustinaLaxague,
    imgAlt: "avatar Agustina Laxague",
  },

  {
    id: "Testimonial 12",
    author: "Maximiliano García",
    comment:
      "Me anote para hacer la preparación del parcial y termine haciendo el curso paralelo, muy buenos profesores y buena disponibilidad para coordinar los horarios de clase",
    img: ImgMaximilianoGarcia,
    imgAlt: "avatar Maximiliano García",
  },
];
export const dotsTestimonialArrayNotMobile = [
  {
    id: "Testimonial dot 1",
    name: "dot 1",
  },
  {
    id: "Testimonial dot 2",
    name: "dot 2",
  },
  {
    id: "Testimonial dot 3",
    name: "dot 3",
  },
  {
    id: "Testimonial dot 4",
    name: "dot 4",
  },
];
export const dotsTestimonialArrayMobile = [
  {
    id: "Testimonial dot 1",
    name: "dot 1",
  },
  {
    id: "Testimonial dot 2",
    name: "dot 2",
  },
  {
    id: "Testimonial dot 3",
    name: "dot 3",
  },
  {
    id: "Testimonial dot 4",
    name: "dot 4",
  },
  {
    id: "Testimonial dot 5",
    name: "dot 5",
  },
  {
    id: "Testimonial dot 6",
    name: "dot 6",
  },
  {
    id: "Testimonial dot 7",
    name: "dot 7",
  },
  {
    id: "Testimonial dot 8",
    name: "dot 8",
  },
  {
    id: "Testimonial dot 9",
    name: "dot 9",
  },
  {
    id: "Testimonial dot 10",
    name: "dot 10",
  },
  {
    id: "Testimonial dot 11",
    name: "dot 11",
  },
  {
    id: "Testimonial dot 12",
    name: "dot 12",
  },
];
