import React from "react";

const RenderServiceFlyers = (servicesArray) => {
  return (
    <div className="container grid grid--2-cols margin-bottom-sm">
      {servicesArray.map((service) => {
        return (
          <div className={service.serviceClass}>
            <div className="service-name">
              <p className="service-title">{service.serviceName}</p>
            </div>
            <div className="service-features">
              <p className="service-begining">
                <ion-icon class="service-icon" name="today-outline"></ion-icon>
                <strong>Comienzo: </strong>
                {service.serviceStartingDate}
              </p>
              <p className="service-duration">
                <ion-icon class="service-icon" name="timer-outline"></ion-icon>
                <strong>Duración: </strong> {service.serviceDuration}
              </p>
              <ul className="service-list">
                {service.serviceItems.map((item) => {
                  return (
                    <li className="service-item">
                      <ion-icon
                        class="service-icon"
                        name="checkmark-outline"
                      ></ion-icon>
                      {item}
                    </li>
                  );
                })}
              </ul>
            </div>
            <a href="#cta" className="btn btn-services">
              Me interesa!
            </a>
          </div>
        );
      })}
    </div>
  );
};

export default RenderServiceFlyers;
