import React from "react";
import JoinUsForm from "./JoinUsForm";

class JoinUs extends React.Component {
  render() {
    return (
      <section className="join-us" id="join-us">
        <div className="container">
          <div className="join-us-grid">
            <div className="join-us-form-box">
              <h2 className="heading-secondary join-us-heading">
                Se parte de nuestro equipo!
              </h2>
              <p className="join-us-text">
                Si tienes experiencia docente y te gustaría formar parte de
                nuestro equipo, contáctanos para una entrevista! Nos interesa
                saber más acerca de ti!.
              </p>
              <JoinUsForm />
            </div>
            <div
              className="join-us-img-box"
              role="img"
              aria-label="young teacher given onlince classes"
            ></div>
          </div>
        </div>
      </section>
    );
  }
}

export default JoinUs;
