import React from "react";
import heroImgCompressedPng from "../../img/hero/hero-img-compressed.png";
import heroImgCompressedWebp from "../../img/hero/hero-img-compressed.webp";
// import heroImg from "../../img/hero/hero-img.png";

class Hero extends React.Component {
  render() {
    return (
      <div>
        <section className="section-hero" id="#hero">
          <div className="hero">
            <div className="hero-text-box">
              <h1 className="heading-primary hero-title">
                La academia online para aprobar la FING.
              </h1>
              <p className="hero-text">
                Nuestro equipo está formado por estudiantes avanzados e
                ingenieros egresados de la FING, por lo que sabemos lo que
                necesitás! Más del
                <strong> 93%</strong> de nuestros alumnos nos recomiendan!
              </p>
              <a href="#cta" className="btn btn-cta margin-right-sm">
                Anotarme Ya!
              </a>
              <a href="#services" className="btn btn-about-us">
                Conócenos &darr;
              </a>
            </div>
            <div className="img-hero-box">
              <picture>
                <source srcSet={heroImgCompressedWebp}></source>
                <source srcSet={heroImgCompressedPng}></source>
                <img
                  className="hero-img"
                  // src="{heroImg}"
                  src={heroImgCompressedWebp}
                  alt="estudiantes en clase online desde su casa"
                  rel="preload"
                />
              </picture>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

export default Hero;
