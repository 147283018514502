import React from "react";
import emailjs from "emailjs-com";

class CtaForm extends React.Component {
  state = {
    name: "",
    email: "",
    tel: "",
  };

  onFormSubmit = (e) => {
    e.preventDefault();
    emailjs
      .sendForm("gmail", "cta_contact_form", e.target, "nV3tTFSl_x7gBrCSM")
      .then(
        (result) => {
          console.log(result.text);
        },
        (error) => {
          console.log(error.text);
        }
      );
    e.target.reset();
    alert(
      `Gracias por tu mensaje! Enseguida te contactaremos a la dirección de email que nos has proporcionado.`
    );
    this.setState({ name: "", email: "", tel: "" });
  };

  render() {
    return (
      <form className="cta-form" onSubmit={this.onFormSubmit}>
        <div>
          <label htmlFor="full-name-cta">Nombre completo</label>
          <input
            name="full-name-cta"
            id="full-name-cta"
            type="text"
            placeholder="Tu Nombre"
            required
            value={this.state.name}
            onChange={(e) => this.setState({ name: e.target.value })}
          />
        </div>
        <div>
          <label htmlFor="email-cta">Email</label>
          <input
            name="email-cta"
            id="email-cta"
            type="email"
            placeholder="tuemail@mail.com"
            required
            value={this.state.email}
            onChange={(e) => this.setState({ email: e.target.value })}
          />
        </div>
        <div>
          <label htmlFor="phone-cta">Número de contacto</label>
          <input
            name="phone-cta"
            id="phone-cta"
            type="tel"
            pattern="[0-9]{9}"
            placeholder="Tu Número"
            required
            value={this.state.tel}
            onChange={(e) => this.setState({ tel: e.target.value })}
          />
        </div>

        <div>
          <label htmlFor="select-where">¿Cómo conociste Fing Twice?</label>
          <select name="where" id="where" required>
            <option>Selecciona una opción:</option>
            <option>Amigos o familia</option>
            <option>FING</option>
            <option>Páginas web relacionadas a la educación</option>
            <option>Instagram</option>
            <option>Whatsapp</option>
            <option>Facebook</option>
            <option>Otro</option>
          </select>
        </div>
        <div>
          <label htmlFor="select-service">¿Cuál servicio te interesa?</label>
          <select name="service" id="service" required>
            <option>Selecciona una opción:</option>
            <option>Curso Paralelo</option>
            <option>Preparación de Parcial</option>
            <option>Preparación de Examen</option>
          </select>
        </div>
        <div>
          <label htmlFor="select-subject">¿Para qué materia?</label>
          <select name="subject" id="subject" required>
            <option>Selecciona una opción:</option>
            <option>CDIV</option>
            <option>CDIVV</option>
            <option>GAL 1</option>
            <option>GAL 2</option>
            <option>MD1</option>
            <option>MD2</option>
            <option>PyE</option>
            <option>Cálculo Vectorial</option>
            <option>Int a las Ec Dif</option>
            <option>Métodos Numéricos</option>
            <option>F1</option>
            <option>F2</option>
            <option>F3</option>
            <option>Mec New</option>
            <option>Elem de Mec de los Fluidos</option>
            <option>Principios de Química</option>
            <option>P1</option>
            <option>P2</option>
            <option>Lógica</option>
          </select>
        </div>

        <button
          className="btn btn--form"
          type="submit"
          onSubmit={this.onFormSubmit}
        >
          Enviar
        </button>
      </form>
    );
  }
}

export default CtaForm;
