import React from "react";
import { useState, useEffect } from "react";

const RenderServiceFeatures = (
  serviceFeatureArray,
  serviceFeatureDotsArray,
  isMobile
) => {
  const dotsArrayFeaturesEl = document.getElementsByClassName(
    "dot-feature-slider"
  );
  const [currentDotEl, setCurrentDotEl] = useState(dotsArrayFeaturesEl[0]);
  const numSlidesServiceFeatures = serviceFeatureArray.length - 1;
  let prevSlide = 0;
  const [currentSlide, setCurrentSlide] = useState(0);

  useEffect(() => {
    if (isMobile) {
      dotsArrayFeaturesEl[0].classList.add("dot-active-features");
      setCurrentDotEl(dotsArrayFeaturesEl[0]);
    }
  }, [isMobile]);

  const onHandleClickSlider = (e) => {
    currentDotEl.classList.remove("dot-active-features");
    prevSlide = currentSlide;
    if (e.target.name === "chevron-back-circle-outline") {
      if (prevSlide === 0) {
        setCurrentSlide(numSlidesServiceFeatures);
        setCurrentDotEl(dotsArrayFeaturesEl[numSlidesServiceFeatures]);
        dotsArrayFeaturesEl[numSlidesServiceFeatures].classList.add(
          "dot-active-features"
        );
      } else {
        setCurrentSlide(prevSlide - 1);
        setCurrentDotEl(dotsArrayFeaturesEl[prevSlide - 1]);
        dotsArrayFeaturesEl[prevSlide - 1].classList.add("dot-active-features");
      }
    }
    if (e.target.name === "chevron-forward-circle-outline") {
      if (prevSlide === numSlidesServiceFeatures) {
        setCurrentSlide(0);
        setCurrentDotEl(dotsArrayFeaturesEl[0]);
        dotsArrayFeaturesEl[0].classList.add("dot-active-features");
      } else {
        setCurrentSlide(prevSlide + 1);
        setCurrentDotEl(dotsArrayFeaturesEl[prevSlide + 1]);
        dotsArrayFeaturesEl[prevSlide + 1].classList.add("dot-active-features");
      }
    }
  };

  const onHandleClickDots = (e) => {
    currentDotEl.classList.remove("dot-active-features");
    switch (e.target.id) {
      case "service feature dot 0": {
        setCurrentSlide(0);
        setCurrentDotEl(dotsArrayFeaturesEl[0]);
        dotsArrayFeaturesEl[0].classList.add("dot-active-features");
        break;
      }
      case "service feature dot 1": {
        setCurrentSlide(1);
        setCurrentDotEl(dotsArrayFeaturesEl[1]);
        dotsArrayFeaturesEl[1].classList.add("dot-active-features");
        break;
      }
      case "service feature dot 2": {
        setCurrentSlide(2);
        setCurrentDotEl(dotsArrayFeaturesEl[2]);
        dotsArrayFeaturesEl[2].classList.add("dot-active-features");
        break;
      }
      case "service feature dot 3": {
        setCurrentSlide(3);
        setCurrentDotEl(dotsArrayFeaturesEl[3]);
        dotsArrayFeaturesEl[3].classList.add("dot-active-features");
        break;
      }
      case "service feature dot 4": {
        setCurrentSlide(4);
        setCurrentDotEl(dotsArrayFeaturesEl[4]);
        dotsArrayFeaturesEl[4].classList.add("dot-active-features");
        break;
      }
      case "service feature dot 5": {
        setCurrentSlide(5);
        setCurrentDotEl(dotsArrayFeaturesEl[5]);
        dotsArrayFeaturesEl[5].classList.add("dot-active-features");
        break;
      }
      case "service feature dot 6": {
        setCurrentSlide(6);
        setCurrentDotEl(dotsArrayFeaturesEl[6]);
        dotsArrayFeaturesEl[6].classList.add("dot-active-features");
        break;
      }
      case "service feature dot 7": {
        setCurrentSlide(7);
        setCurrentDotEl(dotsArrayFeaturesEl[7]);
        dotsArrayFeaturesEl[7].classList.add("dot-active-features");
        break;
      }

      default:
    }
  };

  if (isMobile) {
    return (
      <div className="container feature-grid-slider">
        <div className="arrow-feature-slider">
          <div className="arrow-left-feature-slider btn-arrow-feature-slider">
            <button
              className="btn-arrow-feature-slider"
              onClick={onHandleClickSlider}
            >
              <ion-icon
                name="chevron-back-circle-outline"
                class="arrow-icon-feature-slider"
              ></ion-icon>
            </button>
          </div>
          <div className="feature">
            <ion-icon
              class="feature-icon"
              name={serviceFeatureArray[currentSlide].featureIcon}
            ></ion-icon>
            <div className="feature-title">
              {serviceFeatureArray[currentSlide].featureTitle}
            </div>
            <div className="feature-description">
              {serviceFeatureArray[currentSlide].featureDescription}
            </div>
          </div>
          <div className="arrow-right-feature-slider btn-arrow-feature-slider">
            <button
              className="btn-arrow-feature-slider "
              onClick={onHandleClickSlider}
            >
              <ion-icon
                name="chevron-forward-circle-outline"
                class="arrow-icon-feature-slider"
              ></ion-icon>
            </button>
          </div>
        </div>

        <div className="container-dot-feature-slider">
          {serviceFeatureDotsArray.map((dot) => {
            return (
              <button
                className="btn-dot-feature-slider"
                onClick={onHandleClickDots}
                // id={dot.id}
              >
                <ion-icon
                  name="ellipse-outline"
                  class="dot-feature-slider"
                  id={dot.id}
                ></ion-icon>
              </button>
            );
          })}
        </div>
      </div>
    );
  } else {
    return (
      <div className="container feature-grid-normal">
        {serviceFeatureArray.map((serviceFeature) => {
          return (
            <div className="feature">
              <ion-icon
                class="feature-icon"
                name={serviceFeature.featureIcon}
              ></ion-icon>
              <div className="feature-title">{serviceFeature.featureTitle}</div>
              <div className="feature-description">
                {serviceFeature.featureDescription}
              </div>
            </div>
          );
        })}
      </div>
    );
  }
};

export default RenderServiceFeatures;
